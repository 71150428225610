<template>
  <div id="line-map-full" class="line-map"></div>
</template>
<script setup>
import goongjs from '@goongmaps/goong-js';
import { onMounted } from 'vue';
import { ref, defineEmits } from 'vue';
import { MARKER_TYPE } from '@/util/common-constant.js';

goongjs.accessToken = window.VUE_APP_API_GOONG_MAP_TOKEN;
const emits = defineEmits(['click-marker', 'click-marker-ts']);
const props = defineProps({
  center: {
    type: Object,
    default: () => {
      return { lat: 21.051528423347538, lng: 105.80015809649605 };
    },
  },
  markers: {
    type: Array,
    default: () => [],
  },
  markerTrinhSat: {
    type: Array,
    default: () => [],
  },
  flightPath: {
    type: Array,
    default: () => {
      return [];
    },
  },
  flightPath2: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const iconType = {};
iconType[MARKER_TYPE.CAMERA_SELECTED] = {
  icon: require('@/static/img/map/camera.png'),
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.CAMERA_DEFAULT] = {
  icon: require('@/static/img/map/camera-default.png'),
  classAnimate: '',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.DEFAULT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.DO] = {
  icon: require('@/static/img/map/do.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.VANG] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.VANG_SELECTED] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro3_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.CURRENT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px]  bg-[#3A5EF6] !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.TRINH_SAT] = {
  icon: require('@/static/img/map/scout.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro4_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.LOCATION] = {
  icon: require('@/static/img/map/location.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.TIM] = {
  icon: require('@/static/img/map/tim.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.TIM_SELECTED] = {
  icon: require('@/static/img/map/tim.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-[#7D3AAE] !opacity-25 rounded-full',
};
let map = null;
let markers = [];
let markerTrinhSat = [];
let listMarker = {};
onMounted(() => {
  initMap();
});
const initMap = () => {
  map = new goongjs.Map({
    container: 'line-map-full',
    style: 'https://tiles.goong.io/assets/goong_map_web.json', // stylesheet location
    center: [props.center.lng, props.center.lat], // starting position [lng, lat]
    zoom: 12, // starting zoom
    maxZoom: 16,
  });
  map.addControl(new goongjs.NavigationControl());
  addMarkerToMap(props.markers);
  addLineString(props.markers);
  if (props.markerTrinhSat.length > 0) {
    setMarkerTrinhSat(props.markerTrinhSat);
  }
  map.setZoom(14);
};
const addOneMarker = (mk, color) => {
  // create DOM element for the marker
  let el = buildContent(mk, color);
  // create the popup
  let marker = new goongjs.Marker(el).setLngLat([mk.lng, mk.lat]);
  marker.addTo(map);
  if (mk.detailInfo) {
    let popup = new goongjs.Popup({
      offset: 20,
      maxWidth: '500px',
      focusAfterOpen: false,
    }).setHTML(mk.detailInfo);
    marker.setPopup(popup);
    if (mk.showInfo) marker.togglePopup();
  }
  if (mk.type === MARKER_TYPE.TRINH_SAT) {
    el.addEventListener('click', function () {
      emits('click-marker-ts', mk);
    });
  } else
    el.addEventListener('click', function () {
      emits('click-marker', mk);
    });
  return marker;
};
const addMarkerToMap = (listData) => {
  for (let key in listData) {
    if (!listMarker[key]) listMarker[key] = [];
    for (let data of listData[key].markers) {
      let marker = addOneMarker(data, listData[key].color);
      listMarker[key].push(marker);
    }
  }
};
const removeAllMarker = () => {
  for (let id in listMarker) {
    for (let mk of listMarker[id]) {
      mk.remove();
    }
    listMarker[id] = [];
  }
};
function buildContent(property, color) {
  const content = document.createElement('div');
  content.classList = `marker-goong group absolute`;
  content.id = `marker-goong-${property.id}`;
  if (property.type && iconType[property.type]) {
    content.innerHTML = property.infoWindow
      ? `<div class="relative icon-marker block h-[30px] w-[30px]">
          <span class="${iconType[property.type].classAnimate}"></span>
            <img src="${iconType[property.type].icon}" class="absolute z-1"/>
        </div>
        <div class="details absolute bottom-[46px] flex group-hover:opacity-[1] opacity-[0] flex-col " >
          <div class="hidden group-hover:flex">${property.infoWindow}</div>
          <div class="w-[20px] h-[20px] bg-[#0F0F15] group-hover:block hidden absolute bottom-[-8px] after-detail" ></div>
        </div>`
      : `<div class="relative icon-marker block h-[30px] w-[30px]">
          <span class="${iconType[property.type].classAnimate}"></span>
            <img src="${iconType[property.type].icon}" class="absolute z-1"/>
        </div>`;
  } else {
    content.innerHTML = property.infoWindow
      ? `<div class="relative icon-marker block h-[30px] w-[30px]">
          <span class="${
            property.showInfo
              ? 'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] !opacity-25 rounded-full'
              : ''
          }" ${
          property.showInfo ? `style="background-color:${color}"` : ''
        }></span>
          <div class="h-[30px] w-[30px] flex items-center justify-center rounded-full border-[5px] border-solid border-white-a500 absolute z-1" style="background-color:${color}"></div>
        </div>
        <div class="details absolute bottom-[46px] flex group-hover:opacity-[1] opacity-[0] flex-col " >
          <div class="hidden group-hover:flex">${property.infoWindow}</div>
          <div class="w-[20px] h-[20px] bg-[#0F0F15] group-hover:block hidden absolute bottom-[-8px] after-detail" ></div>
        </div>`
      : `<div class="relative icon-marker block h-[30px] w-[30px]">
          <span class="${
            property.showInfo
              ? 'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] !opacity-25 rounded-full'
              : ''
          }" ${
          property.showInfo ? `style="background-color:${color}"` : ''
        }></span>
          <div class="h-[30px] w-[30px] flex items-center justify-center rounded-full border-[5px] border-solid border-white-a500 absolute z-1" style="background-color:${color}"></div>
        </div>`;
  }
  return content;
}
const setMarker = (list) => {
  removeAllMarker();
  addMarkerToMap(list);
};
const moveToLocation = (lat, lng) => {
  map.flyTo({
    center: [lng, lat],
  });
};
const updateLineString = (lineString, id, color) => {
  if (map.getSource(`route-${id}`)) {
    map.getSource(`route-${id}`).setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: lineString,
      },
    });
  } else {
    createLineString(lineString, id, color);
  }
};
const addLineString = (listData) => {
  for (let i in listData) {
    createLineString(
      listData[i].path,
      listData[i].profileUUID,
      listData[i].color
    );
  }
};
const createLineString = (lineString, id, color) => {
  const arrowSvg = require(`@/static/img/map/arrow-right.svg`); //get the img path
  let img = new Image(12, 12); //create HTMLElement
  img.src = arrowSvg; //set HTMLELement img src
  img.style.fill = 'red';
  img.onload = () => map.addImage(`arrow-right-${id}`, img); //when img is loaded, add it to the map
  map.addSource(`route-${id}`, {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: lineString,
      },
    },
  });
  map.addLayer({
    id: `line-string-${id}`,
    type: 'line',
    source: `route-${id}`,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': 2,
    },
  });
  map.addLayer({
    id: `arrow-icon-${id}`,
    type: 'symbol',
    source: `route-${id}`,
    paint: {},
    layout: {
      'symbol-placement': 'line',
      'icon-image': `arrow-right-${id}`,
      'symbol-avoid-edges': true,
    },
  });
};

const clearMarkerTrinhSat = () => {
  for (let marker of markerTrinhSat) {
    marker.remove();
  }
  markerTrinhSat = [];
};
const setMarkerTrinhSat = (list) => {
  clearMarkerTrinhSat();
  for (let i in list) {
    let mk = addOneMarker(list[i]);
    markerTrinhSat.push(mk);
  }
};
const resize = () => {
  map.resize();
};

defineExpose({
  moveToLocation,
  setMarker,
  updateLineString,
  setMarkerTrinhSat,
  clearMarkerTrinhSat,
  resize,
  initMap,
});
</script>

<style lang="scss">
.line-map {
  height: 100vh;
}
.line-map .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin-top: 60px !important;
}
.line-map .mapboxgl-popup-content {
  background-color: #0F0F15;
  padding: 16px !important;
}
.line-map .mapboxgl-popup-content .mapboxgl-popup-close-button {
  right: 4px;
  top: 4px;
  color: #fff;
  font-size: 20px;
}
.line-map .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  grid-gap: 22px;
  gap: 22px;
  width: max-content;
  overflow: auto;
  z-index: 1;
}
.line-map .mapboxgl-popup-tip {
  border-top-color: #0F0F15;
}
.line-map .marker-goong {
  align-items: flex-end;
  background-color: transparent;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  transition: all 0.2s ease-out;
  width: 30px;
}
.line-map .marker-goong .details {
  background-color: #0F0F15;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  width: auto;
  align-items: center;
  width: fit-content;
  flex: 1;
  transition: all 0.3s ease-out;
}
.line-map .marker-goong .details .after-detail {
  transform: rotate(45deg);
}
</style>
