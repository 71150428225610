<template>
  <div class="">
    <div
      class="flex justify-between bg-ems-gray800 pl-16px pr-24px border-b-1 border-solid border-ems-gray700 sticky top-60px z-1100 relative"
    >
      <div>
        <a-tabs v-model:activeKey="activeTab" @change="onChangeTab($event)">
          <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div
      v-if="activeTab === IN_OUT_TAB.LIST"
      class="bg-ems-gray800 h-[calc(100vh-110px)]"
    >
      <List />
    </div>
    <Dashboard v-if="activeTab === IN_OUT_TAB.DASHBOARD" />
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { IN_OUT_TAB } from '@/util/common-constant.js';
import List from './components/List.vue';
import Dashboard from './components/dashboard.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { dispatch } = useStore();
const activeTab = ref(route.query.tab ? route.query.tab : 'list');
const tabs = [
  {
    key: IN_OUT_TAB.LIST,
    tabName: t('in_out_event.list'),
  },
  {
    key: IN_OUT_TAB.DASHBOARD,
    tabName: t('in_out_event.dashboard'),
  },
];

watch(
  () => route.query.tab,
  () => {
    if (!route.query.tab) {
      activeTab.value = 'list';
    } else {
      activeTab.value = route.query.tab;
    }
  },
  {
    immediate: true,
    deep: true,
  }
);
onMounted(async () => {
  await dispatch('setTitleHeader', t('in_out_event.in_out_event_management'));
  await dispatch('tracingObject/getListCameraByGroups', []);
  await dispatch('inOutEvent/getKeyValVehicle');
});
const onChangeTab = (key) => {
  router.push({
    query: { tab: key },
  });
  window.scrollTo({ top: 0, behavior: 'instant' });
};
</script>
