<template>
  <div class="clearfix">
    <a-upload
      v-model:file-list="list"
      list-type="picture-card"
      :customRequest="customRequest"
      @preview="handlePreview"
      @change="change"
      :before-upload="beforeUpload"
      :accept="accept"
      :disabled="disabled"
      style="width: 200px"
    >
      <div v-if="!disabled && (isMul || list.length === 0)">
        <plus-outlined class="custom-svg"/>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :title="previewTitle"
      :footer="null"
      @cancel="handleCancel"
    >
      <video v-if="type === 'video'" width="470" height="340" controls>
        <source :src="previewImage" type="video/mp4" />
      </video>
      <img v-else alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import {PlusOutlined} from '@ant-design/icons-vue';
import {defineComponent, ref} from 'vue';
import {DataService} from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import {notification} from 'ant-design-vue';
import VueTypes from 'vue-types';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default defineComponent({
  name: 'Upload',
  components: {
    PlusOutlined,
  },
  props: {
    value: VueTypes.array.def('' | []),
    disabled: VueTypes.bool.def(false),
    isMul: VueTypes.bool.def(false),
    accept: VueTypes.string,
    beforeUpload: VueTypes.func,
    rootPath: VueTypes.string,
    // type image, video
    type: VueTypes.string.def('image'),
  },
  setup(props, { emit }) {
    const previewVisible = ref(false);
    const previewImage = ref('');
    const previewTitle = ref('');
    const fileList = ref([]);
    const { t } = useI18n();
    const handleCancel = () => {
      previewVisible.value = false;
      previewTitle.value = '';
    };
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
      previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    };
    const customRequest = async (options) => {
      const { onSuccess, onError, file, onProgress } = options;
      const fmData = new FormData();
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        onUploadProgress: (event) => {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      };
      fmData.append('file', file);
      fmData.append('rootPath', props.rootPath);
      try {
        const res = await DataService.upload(
          ConstantAPI.upload_service.UPLOAD,
          fmData,
          config
        );
        if (res.code === '200') {
          onSuccess(res.data.url);
        } else {
          notification.error({
            message: t('common.notification'),
            description: res.error,
            duration: 4,
          });
          onError();
        }
      } catch (err) {
        onError({ err });
      }
    };
    const change = () => {
      list.value = list.value.map((e) => ({
        ...e,
        url: _.isNil(e.url) ? e.response : e.url,
      }));
      emit(
        'update:value',
        list.value.map((e) => {
          return _.isNil(e.url) ? e.response : e.url;
        })
      );
    };
    const list = ref(
      (props.value).map((e) => ({
        status: 'done',
        url: e,
        name: e && e.substring(e.lastIndexOf('/') + 1),
      }))
    );
    return {
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      previewTitle,
      customRequest,
      change,
      list,
    };
  },
});
</script>
<style lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}
.ant-modal-title {
  max-width: 450px;
}
.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.ant-upload .anticon svg {
  --tw-text-opacity: 1;
  color: rgba(91, 91, 159, var(--tw-text-opacity));
}
.ant-upload.ant-upload-select-picture-card {
  background-color: #2B2A3A !important;
  border: 1px dashed rgba(123, 123, 123, 1);
  border-radius: unset !important;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 100%;
  padding: 8px;
}
.custom-svg svg {
  width: 29px;
  height: 29px;
}
</style>
