<template>
  <div id="line-map"></div>
</template>
<script setup>
import goongjs from '@goongmaps/goong-js';
import { onMounted } from 'vue';
import { ref, defineEmits } from 'vue';
import { MARKER_TYPE } from '@/util/common-constant.js';

goongjs.accessToken = window.VUE_APP_API_GOONG_MAP_TOKEN;
const emits = defineEmits(['click-marker', 'click-marker-ts']);
const props = defineProps({
  center: {
    type: Object,
    default: () => {
      return { lat: 21.051528423347538, lng: 105.80015809649605 };
    },
  },
  markers: {
    type: Array,
    default: () => [],
  },
  markerTrinhSat: {
    type: Array,
    default: () => [],
  },
  flightPath: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
const iconType = {};
iconType[MARKER_TYPE.CAMERA_SELECTED] = {
  icon: require('@/static/img/map/camera.png'),
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.CAMERA_DEFAULT] = {
  icon: require('@/static/img/map/camera-default.png'),
  classAnimate: '',
  class: 'top-[21px] left-0',
};
iconType[MARKER_TYPE.DEFAULT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.DO] = {
  icon: require('@/static/img/map/do.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-main1 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.VANG] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
iconType[MARKER_TYPE.VANG_SELECTED] = {
  icon: require('@/static/img/map/vang.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro3_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.CURRENT] = {
  icon: require('@/static/img/map/marker.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px]  bg-[#3A5EF6] !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.TRINH_SAT] = {
  icon: require('@/static/img/map/scout.png'),
  class: 'top-[15px] left-0',
  classAnimate:
    'animate-ping top-0 left-0 absolute inline-flex h-[30px] w-[30px] bg-ems-boTro4_600 !opacity-25 rounded-full',
};
iconType[MARKER_TYPE.LOCATION] = {
  icon: require('@/static/img/map/location.png'),
  class: 'top-[15px] left-0',
  classAnimate: '',
};
let map = null;
let markers = [];
let markerTrinhSat = [];
onMounted(() => {
  map = new goongjs.Map({
    container: 'line-map',
    style: 'https://tiles.goong.io/assets/goong_map_web.json', // stylesheet location
    center: [props.center.lng, props.center.lat], // starting position [lng, lat]
    zoom: 12, // starting zoom
    maxZoom: 16,
  });
  map.addControl(new goongjs.NavigationControl());
  props.markers.forEach((e) => {
    let mk = addMarker(e);
    markers.push(mk);
  });
  if (props.markerTrinhSat.length > 0) {
    setMarkerTrinhSat(props.markerTrinhSat);
  }
  if (props.flightPath && props.flightPath.length > 0) {
    createLineString(props.flightPath);
  } else {
    createLineString([]);
  }
  map.setZoom(14);
});
const addMarker = (mk) => {
  // create DOM element for the marker
  let el = buildContent(mk);
  if (mk.type === MARKER_TYPE.TRINH_SAT) {
    el.addEventListener('click', function () {
      emits('click-marker-ts', mk);
    });
  } else
    el.addEventListener('click', function () {
      emits('click-marker', mk);
    });
  // add marker to map
  let marker = new goongjs.Marker(el).setLngLat([mk.lng, mk.lat]);
  marker.addTo(map);
  return marker;
};
const removeAllMarker = () => {
  for (let marker of markers) {
    marker.remove();
  }
  markers = [];
};

function buildContent(property) {
  const content = document.createElement('div');
  content.classList = `marker-goong group absolute`;
  content.id = `marker-goong-${property.id}`;
  if (property.infoWindow) {
    content.innerHTML = `
      <div class="relative icon-marker block h-[30px] w-[30px]">
        <span class="${iconType[property.type].classAnimate}"></span>
          <img src="${iconType[property.type].icon}" class="absolute z-1"/>
      </div>
      <div class="details absolute bottom-[46px] flex ${
        property.showInfo
          ? 'opacity-[1]'
          : 'group-hover:opacity-[1] opacity-[0]'
      } flex-col " >
        <div class="${
          property.showInfo ? 'flex' : 'hidden group-hover:flex'
        } ">${property.infoWindow}</div>
        <div class="w-[20px] h-[20px] bg-[#0F0F15] ${
          property.showInfo ? 'block' : 'group-hover:block hidden'
        } absolute bottom-[-8px] after-detail" ></div>
      </div>
      `;
  } else {
    content.innerHTML = `
    <div class="relative icon-marker block h-[30px] w-[30px]">
        <span class="${iconType[property.type].classAnimate}"></span>
          <img src="${iconType[property.type].icon}" class="absolute z-1"/>
      </div>
        `;
  }
  return content;
}

const setMarker = (list) => {
  removeAllMarker();
  for (let i in list) {
    let mk = addMarker(list[i]);
    markers.push(mk);
  }
};
const moveToLocation = (lat, lng) => {
  map.flyTo({
    center: [lng, lat],
  });
};
const updateLineString = (lineString) => {
  map.getSource('route').setData({
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: lineString,
    },
  });
};

const createLineString = (lineString) => {
  map.on('load', function () {
    const arrowSvg = require('@/static/img/map/arrow-right.png'); //get the img path
    const img = new Image(12, 12); //create HTMLElement
    img.src = arrowSvg; //set HTMLELement img src
    img.onload = () => map.addImage('arrow-right', img); //when img is loaded, add it to the map
    map.addSource('route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: lineString,
        },
      },
    });
    map.addLayer({
      id: 'line-string',
      type: 'line',
      source: 'route',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': 'red',
        'line-width': 2,
      },
    });
    map.addLayer({
      id: 'arrow-icon',
      type: 'symbol',
      source: 'route',
      paint: {},
      layout: {
        'symbol-placement': 'line',
        'icon-image': 'arrow-right',
        'symbol-avoid-edges': true,
      },
    });
  });
};

const clearMarkerTrinhSat = () => {
  for (let marker of markerTrinhSat) {
    marker.remove();
  }
  markerTrinhSat = [];
};
const setMarkerTrinhSat = (list) => {
  clearMarkerTrinhSat();
  for (let i in list) {
    let mk = addMarker(list[i]);
    markerTrinhSat.push(mk);
  }
};
const resize = () => {
  map.resize();
};
const fitBounds = () => {
  let arr = [];
  for (let key in markers) {
    arr.push([markers[key].getLngLat().lng, markers[key].getLngLat().lat]);
  }
  for (let key in markerTrinhSat) {
    arr.push([
      markerTrinhSat[key].getLngLat().lng,
      markerTrinhSat[key].getLngLat().lat,
    ]);
  }
  if (arr.length > 0)
    map.fitBounds(arr, {
      padding: { top: 10, bottom: 25, left: 15, right: 10 },
    });
};
defineExpose({
  moveToLocation,
  setMarker,
  updateLineString,
  setMarkerTrinhSat,
  clearMarkerTrinhSat,
  resize,
  fitBounds,
});
</script>

<style lang="scss">
#line-map {
  height: 700px;
}
#line-map .mapboxgl-popup-content {
  background-color: #0F0F15;
  padding: 16px !important;
}
#line-map .mapboxgl-popup-content .mapboxgl-popup-close-button {
  right: 4px;
  top: 4px;
  color: #fff;
  font-size: 20px;
}
#line-map .content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  grid-gap: 22px;
  gap: 22px;
  width: max-content;
  overflow: auto;
  z-index: 1;
}
#line-map .mapboxgl-popup-tip {
  border-top-color: #0F0F15;
}
#line-map .marker-goong {
  align-items: flex-end;
  background-color: transparent;
  border-radius: 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 30px;
  justify-content: center;
  transition: all 0.2s ease-out;
  width: 30px;
}
#line-map .marker-goong .details {
  background-color: #0F0F15;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  width: auto;
  align-items: center;
  width: fit-content;
  flex: 1;
  transition: all 0.3s ease-out;
}
#line-map .marker-goong .details .after-detail {
  transform: rotate(45deg);
}
</style>
