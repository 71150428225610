<template>
  <div class="drawer-custom">
    <a-drawer
      v-model:open="props.open"
      :visible="props.visible"
      :width="props.width"
      :closable="false"
      :footer-style="{ textAlign: 'right' }"
      @close="emits('onClose', true)"
    >
      <img src="@/static/img/drawer-bg.png" alt="drawer-bg" class="bg-drawer" />
      <div
        class="w-[19px] cursor-pointer absolute top-[40%] left-[2px] z-60 icon-collapse-drawer"
        @click="onHide()"
        v-if="isShowHideBtn"
      />
      <div
        class="absolute w-full top-[88px] pr-[22px] pl-[34px] bg-transparent text-white focus:outline-none h-[calc(100%-200px)]"
      >
        <div class="grid grid-cols-2 mb-4">
          <h1 class="text-[20px] text-white whitespace-nowrap">
            {{ title ? $t(`${title}`) : $t('common.search') }}
          </h1>
          <div class="flex item-center justify-end">
            <div
              class="cursor-pointer icon-close-drawer"
              @click="emits('onClose', false)"
            />
          </div>
        </div>
        <Suspense>
          <template #default>
            <div
              v-if="visible"
              class="w-full bg-transparent text-white focus:outline-none h-[calc(100%-48px)] overflow-auto pr-1"
            >
              <component
                :is="props.component"
                v-bind="$props"
                @onSearchAdvanced="emits('onSearchAdvanced', $event)"
                @resetCameraId="emits('resetCameraId', $event)"
                @saveSuccess="saveSuccesss"
                @moveToProfile="emits('moveToProfile', $event)"
              />
            </div>
          </template>
          <template #fallback>
            <cds-loading />
          </template>
        </Suspense>
      </div>
      <div
        v-if="showBtnSearch"
        class="flex items-center justify-center absolute bottom-[60px] w-full"
      >
        <clip-button
          type="primary"
          @click="emits('onSearchAdvanced')"
          class="!px-[25px]"
          >{{ $t('common.search') }}
        </clip-button>
      </div>
    </a-drawer>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import ClipButton from '@/components/buttons/ClipButton.vue';

const props = defineProps({
  open: {
    default: {},
  },
  visible: {
    default: false,
    required: true,
  },
  component: {
    required: true,
  },
  formState: {
    default: {},
    required: true,
  },
  width: {
    default: 450,
  },
  title: {
    default: null,
  },
  isShowHideBtn: {
    type: Boolean,
    default: true,
  },
  isCreateSubGroup: {
    type: Boolean,
    default: false,
  },
  parent: {
    type: Object,
    default: () => {},
  },
  showBtnSearch: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'onCancel',
  'onSubmit',
  'onClose',
  'onSearchAdvanced',
  'saveSuccesss',
  'resetCameraId',
  'onHide',
]);

const saveSuccesss = () => {
  emits('saveSuccesss');
};
const onClose = (isSaveData = false) => {
  emits('onClose', isSaveData);
};
const onHide = () => {
  emits('onHide');
};
</script>

<style lang="scss">

.icon-collapse-drawer {
  background-image: url('../../static/img/icon/close-drawer.svg');
  background-size: cover;
  height: 67px;
  &:hover {
    background-image: url('../../static/img/icon/close-drawer-active.svg');
  }
}

.icon-close-drawer {
  background-image: url('../../static/img/icon/cancel.svg');
  background-size: cover;
  height: 24px;
  width: 24px;
  &:hover {
    background-image: url('../../static/img/icon/cancel-active.svg');
  }
}

.ant-drawer-content {
  background-color: transparent;
  overflow: unset;
  //clip-path: polygon(5% 0, 100% 0, 100% 20%, 100% 80%, 100% 100%, 5% 100%, 0 96%, 0 4%);
  //height: 88% !important;
  //margin-top: 14%;
  //background-color: rgba(85, 85, 85, 1) !important;
  //color: white;
  //border: solid rgba(233, 233, 233, 1);
  //border-width: unset;
}

.ant-drawer-body {
  flex-grow: 1;
  padding: 0px;
  padding-top: 58px;
  overflow: unset;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  position: relative;
  height: 100%;
}

.bg-drawer {
  width: 451px;
  max-width: none;
  height: calc(100% - 40px);
  margin-top: 2px;
  //stroke-width: 1px;
  //stroke: #5D5D5D;
  filter: drop-shadow(0px 4px 41px #000);
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  height: 48px;
  color: rgba(123, 123, 123, 1);
  font-size: 14px;
}

</style>
