<template>
  <div class="bg-ems-gray1000">
    <div
      class="flex gap-[24px] bg-ems-gray900 py-15px pl-16px pr-24px border-b-1 border-solid border-ems-gray700 sticky top-60px z-1100 relative"
    >
      <div
        class="flex items-center gap-[8px] hover:cursor-pointer text-ems-main1 hover:text-ems-main2"
        @click="onBack"
      >
        <div class="p-2">
          <img
            src="/assets/ems/arrow-left.png"
            alt="left"
            class="block mx-auto"
            width="8"
            height="4"
          />
        </div>
        <span class="font-bold">{{ t('common.comeback') }}</span>
      </div>
      <div class="bg-ems-main2 rounded-8px">
        <div
          v-if="tracingObjectDetail.name"
          class="h-full text-white-a500 px-10px py-4px hover:cursor-pointer"
          @click="
            copyContent(
              tracingObjectDetail.name
                ? tracingObjectDetail.name
                : tracingObjectDetail.id
            )
          "
        >
          {{
            tracingObjectDetail.name
              ? tracingObjectDetail.name
              : tracingObjectDetail.id
          }}
        </div>
      </div>
      <div
        class="flex justify-center items-center gap-16px absolute right-24px top-10px"
      >
        <ClipButton
          v-if="
            activeTab === TAB_KEY.INFO &&
            checkHasPermission(perms, OBJECT_PERM.EDIT)
          "
          class="flex items-center"
          :type="'primary'"
          :disabled="isLoading"
          @click="onEditObject"
        >
          <cds-feather-icons type="plus" size="18" />
          <span class="ml-6px">{{ t('object_tracing.update-info') }}</span>
        </ClipButton>
        <ClipButton
          v-if="
            activeTab === TAB_KEY.INFO &&
            checkHasPermission(perms, TRACING_PERM.DELETE)
          "
          class="flex items-center !px-[30px]"
          :type="'primary'"
          :disabled="isLoading"
          @click="onDelete"
        >
          <cds-feather-icons type="trash-2" size="18" />
          <span class="ml-6px">{{ t('common.delete') }}</span>
        </ClipButton>
        <ClipButton
          class="flex items-center !px-10 gap-[6px]"
          :type="'secondary'"
          @click="onClose"
        >
          <cds-feather-icons type="x" size="20" />
          <span>{{ t('common.close') }}</span>
        </ClipButton>
      </div>
    </div>
    <div
      ref="tabContainer"
      class="flex justify-between bg-ems-gray800 pl-16px pr-24px border-b-1 border-solid border-ems-gray700 sticky top-119px z-20 w-full relative"
    >
      <div
        :class="isBreakLine ? 'pt-60px' : 'pt-26px'"
        class="flex items-center gap-2"
      >
        <!-- <div class="hover:bg-ems-gray700 p-2 cursor-pointer" @click="onBack">
          <img
            src="/assets/ems/left.png"
            alt="left"
            class="block mx-auto"
            width="24"
            height="24"
          />
        </div> -->
        <a-tabs v-model:activeKey="activeTab" @change="onChangeTab($event)">
          <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tabName">
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <Suspense>
      <template #default>
        <component :is="component" />
      </template>
      <template #fallback>
        <div class="spin-custom">
          <cds-loading />
        </div>
      </template>
    </Suspense>
    <showConfirmModal ref="showConfirmCustom"></showConfirmModal>
  </div>
</template>
<script setup>
import {
  computed,
  defineAsyncComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import {
  FORM_MODE,
  TAB_KEY,
  OBJECT_PERM,
  TRACING_PERM,
} from '../../../util/common-constant';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ClipButton from '@/components/buttons/ClipButton.vue';
import showConfirmModal from '@/components/show-confirm/showConfirm.vue';
import { copyContent, checkHasPermission } from '@/util/common-utils';

const InformationTab = defineAsyncComponent(() =>
  import('./components/InformationTab.vue')
);
// const CameraTab = defineAsyncComponent(() =>
//   import('./components/CameraTab.vue')
// );
const LocationTab = defineAsyncComponent(() =>
  import('./components/LocationTab.vue')
);
const SocialTab = defineAsyncComponent(() =>
  import('./components/SocialTab.vue')
);
const { t } = useI18n();
const component = ref('');
const route = useRoute();
const router = useRouter();
const { state, dispatch } = useStore();
const activeTab = ref(TAB_KEY.INFO);
const perms = computed(() => state.auth.userInfo.permissions);
const isLoading = computed(() => state.tracingObject.isLoading);
const tabs = [
  {
    key: TAB_KEY.INFO,
    tabName: t('object_tracing.info'),
    component: InformationTab,
  },
  // {
  //   key: TAB_KEY.CAMERA,
  //   tabName: t('object_tracing.camera_online'),
  //   component: CameraTab,
  // },
  {
    key: TAB_KEY.LOCATION,
    tabName: t('object_tracing.location_appears'),
    component: LocationTab,
  },
  {
    key: TAB_KEY.SOCIAL,
    tabName: t('object_tracing.social_relative'),
    component: SocialTab,
  },
];
watch(
  () => route.query,
  () => {
    if (!route.query.tab) {
      activeTab.value = TAB_KEY.INFO;
    } else {
      activeTab.value = route.query.tab;
    }
    // dispatch('ioManagement/setObjectInfoActiveTab', activeTab.value);
    tabs.forEach((item) => {
      if (item.key === activeTab.value) {
        component.value = item.component;
      }
    });
  },
  {
    immediate: true,
    deep: true,
  }
);
const tabContainer = ref(null);
const containerWidth = ref(0);
const getContainerWidth = () => {
  if (tabContainer.value) {
    containerWidth.value = tabContainer.value.clientWidth;
  }
};
const isBreakLine = computed(() => {
  return containerWidth.value - 502 < 570;
});
onMounted(async () => {
  let promises = [dispatch('ioGroup/getAllGroup')];
  dispatch('setTitleHeader', t('object_tracing.header_detail'));
  Promise.all(promises);
  activeTab.value = route.query.tab ? route.query.tab : TAB_KEY.INFO;
  onChangeTab(activeTab.value);
  new ResizeObserver(getContainerWidth).observe(tabContainer.value);
});
onUnmounted(() => {
  dispatch('tracingObject/resetTracingStore');
});
const onChangeTab = (key) => {
  router.push({
    path: '/trace-management/object-tracing/detail',
    query: { ...route.query, tab: key },
  });
};
const tracingObjectDetail = computed(
  () => state.tracingObject.tracingObjectDetail
);
const onEditObject = () => {
  router.push({
    name: '/io-management/object-management/edit',
    query: {
      ...route.query,
      id: route.query.id,
      type: tracingObjectDetail.value.type,
      mode: FORM_MODE.UPDATE,
    },
  });
};
const showConfirmCustom = ref(null);

const onDelete = async () => {
  if (await showConfirmCustom.value.onOpenModal('common.delete_confirm')) {
    dispatch('tracingObject/deleteTracing', [route.query.id]).then(() => {
      router.push('/trace-management/object-tracing');
    });
  }
};
const onBack = () => {
  if (window.history.state.back) {
    history.back();
  } else {
    router.push({
      path: '/trace-management/object-tracing',
      query: {
        page: route.query.page ? route.query.page : 1,
        searchName: route.query.searchName ? route.query.searchName : null,
        searchType: route.query.searchType ? route.query.searchType : null,
        searchGroupId: route.query.searchGroupId
          ? route.query.searchGroupId
          : null,
        searchImage: route.query.searchImage ? route.query.searchImage : null,
        filterType: route.query.filterType ? route.query.filterType : null,
        filterGroup: route.query.filterGroup ? route.query.filterGroup : null,
      },
    });
  }
};
const onClose = () => {
  router.push({
    path: '/trace-management/object-tracing',
    query: {
      page: route.query.page ? route.query.page : 1,
      searchName: route.query.searchName ? route.query.searchName : null,
      searchType: route.query.searchType ? route.query.searchType : null,
      searchGroupId: route.query.searchGroupId
        ? route.query.searchGroupId
        : null,
      searchImage: route.query.searchImage ? route.query.searchImage : null,
      filterType: route.query.filterType ? route.query.filterType : null,
      filterGroup: route.query.filterGroup ? route.query.filterGroup : null,
    },
  });
};
</script>
