<template>
  <div class="custom-select relative">
    <img
      v-if="isShowIconSearched"
      class="absolute top-[32%] translate-y-[-50%] left-3 bg-transparent px-1 text-main-1"
      alt="search"
      src="@/static/img/icon/search.svg"
    />
    <cds-select
      v-model:value="valueSelect"
      :mode="mode"
      style="width: 100%"
      class="ant-select-sm"
      :class="isShowIconSearched ? 'pl-20px' : 'pl-1'"
      :placeholder="placeholder"
      :maxTagCount="maxTagCount"
      :max-tag-text-length="maxTagTextLength"
      :options="listOption"
      :allowClear="allowClear"
      :filter-option="filterOption"
      size="small"
      @change="change"
    >
    </cds-select>
  </div>
</template>
<script>
import { computed, defineComponent, ref, watch } from 'vue';
import VueTypes from 'vue-types';
import { getText } from '@/util/common-utils';
import _ from 'lodash';
import { i18n } from '@/main';

export default defineComponent({
  name: 'Select',
  props: {
    options: VueTypes.array.def([]),
    labelProp: VueTypes.string.def('label'),
    valueProp: VueTypes.string.def('value'),
    maxTagCount: VueTypes.number.def(3),
    maxTagTextLength: VueTypes.number.def(30),
    multiple: VueTypes.bool.def(false),
    placeholder: VueTypes.string,
    allowClear: VueTypes.bool.def(true),
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.number,
      VueTypes.array,
    ]),
    readOnly: VueTypes.bool.def(false),
    isAll: VueTypes.bool.def(false),
    isLabelI18n: VueTypes.bool.def(true),
    isShowIconSearched: VueTypes.bool.def(true),
  },
  setup(props, { emit }) {
    const { t } = i18n.global;
    const listOption = computed(() => {
      const data = _.cloneDeep(props.options);
      if (props.isAll) {
        data.unshift({
          value: null,
          label: 'All',
        });
      }
      return data.map((e) => ({
        label: props.isLabelI18n ? t(e[props.labelProp]) : e[props.labelProp],
        value: e[props.valueProp],
        disabled: e.disabled,
      }));
    });
    const valueSelect = ref(props.value);
    const change = (val) => emit('update:value', val);
    const mode = computed(() => (props.multiple ? 'multiple' : null));
    watch(
      () => props.value,
      (val) => (valueSelect.value = val)
    );
    const getTextLabel = computed(
      () => (value) => t(getText(value, listOption.value))
    );
    const filterOption = (input, option) => {
      return (
        option.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(
            input
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          ) >= 0
      );
    };
    return {
      valueSelect,
      listOption,
      change,
      mode,
      getTextLabel,
      filterOption,
    };
  },
});
</script>
<style lang="scss">
.custom-select {
  background: transparent;
  border-radius: unset;
  border: unset !important;
}
.custom-select .ant-select-sm {
  padding: 6px 12px;
  border-radius: 4px !important;
  border: solid 1px !important;
  line-height: 25px;
  height: 39px !important;
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1 !important;
}
.custom-select.ant-input-sm {
  padding: 6px 0;
}
</style>
