<template>
  <div class="bg-ems-gray1000 min-h-100vh alert-configuration">
    <div
      class="flex justify-between bg-ems-gray800 pl-16px pr-24px border-b-1 border-solid border-ems-gray700"
    >
      <div class="pt-38px pb-13px flex items-center gap-10px">
        <div
            v-if="windowConst.history.state.back"
            class="hover:bg-ems-gray700 cursor-pointer"
            @click="onBack"
        >
          <img
              src="/assets/ems/left.png"
              alt="left"
              class="block mx-auto"
              width="24"
              height="24"
          />
        </div>
        <div class="text-ems-white font-semibold text-16px uppercase">
          {{ t('alert_configuration.alert_configuration') }}
        </div>
      </div>
      <div class="flex flex-col justify-center">
        <ClipButton
          class="flex items-center"
          :type="'primary'"
          @click="onSave"
          v-if="mode !== FORM_MODE.VIEW"
        >
          <span>{{ t('common.save') }}</span>
        </ClipButton>
      </div>
    </div>
    <a-form
      ref="formRef"
      :model="formState"
      name="basic"
      :validate-trigger="['change', 'blur']"
      label-wrap
      label-align="left"
      :rules="rules"
      :scroll-to-first-error="{
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      }"
    >
      <div class="px-16px mt-16px pb-16px">
        <div class="bg-ems-gray800 pt-20px px-20px pb-28px">
          <div
            class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
          >
            {{ t('alert_configuration.alert_object') }}
          </div>
          <div class="h-1px w-full bg-ems-gray700 mt-17px mb-26px"></div>
          <div class="grid grid-cols-4 gap-30px">
            <div class="user-form">
              <a-form-item :name="['warningConfigObjectValue']">
                <a-select
                  v-model:value="formState.warningConfigObjectValue"
                  mode="multiple"
                  :max-tag-count="2"
                  style="width: 100%"
                  :placeholder="$t('alert_configuration.alert_obj_pick')"
                  :options="listIO"
                  :disabled="mode === FORM_MODE.VIEW"
                  :show-arrow="true"
                  :max-tag-text-length="15"
                  :filter-option="false"
                  class="text-white"
                  @change="onChange"
                  @search="onSearch"
                  @select="onSelect"
                  @deselect="onDeSelect"
                  :field-names="{ label: 'name', value: 'id' }"
                  :allow-clear="true"
                >
                  <template v-slot:tagRender="{ label, onClose, option }">
                    <a-popover
                      placement="top"
                      :arrow="false"
                      overlay-class-name="popover-image"
                    >
                      <template #content>
                        <div v-if="option.imagesAvatar">
                          <img
                            :src="option.imagesAvatar"
                            :alt="option.imagesAvatar"
                            class="max-h-210px max-w-355px w-full h-full flex items-center rounded-4px border-1px border-ems-gray500"
                          />
                        </div>
                      </template>
                      <a-tag
                        :closable="false"
                        @close="onClose"
                        class="bg-ems-gray500 text-white font-normal flex justify-center border-ems-gray400 relative"
                        style="cursor: pointer"
                      >
                        {{ label }}&nbsp;&nbsp;
                        <img
                          v-if="mode !== FORM_MODE.VIEW"
                          @click="onClose"
                          src="@/static/img/icon/close-icon.svg"
                          class="absolute top-[-3px] right-[-3px]"
                          alt=""
                        />
                      </a-tag>
                    </a-popover>
                  </template>
                </a-select>
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :name="['name']"
              >
                <cds-input
                  :show-count="false"
                  v-model:value="formState.name"
                  :placeholder="t('alert_configuration.name')"
                  :disabled="mode === FORM_MODE.VIEW"
                  :maxlength="50"
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :name="['status']"
              >
                <cds-select
                  :options="STATUS_LIST"
                  v-model:value="formState.status"
                  :maxlength="100"
                  :disabled="mode === FORM_MODE.VIEW"
                />
              </a-form-item>
            </div>
          </div>
        </div>
      </div>
      <div class="px-16px mt-16px pb-16px">
        <div class="bg-ems-gray800 pt-20px px-20px pb-28px">
          <div
            class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
          >
            {{ t('alert_configuration.alert_method_configuration') }}
          </div>
          <div class="h-1px w-full bg-ems-gray700 mt-17px mb-26px"></div>
          <div class="grid grid-cols-4 gap-30px">
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :name="['loaiDoiTuong']"
              >
                <template #label>
                  <a-checkbox
                    v-model:checked="formState.warningBySound"
                    class="text-ems-gray300 font-normal checkbox-custom"
                    :disabled="mode === FORM_MODE.VIEW"
                    >{{ t('alert_configuration.alert_by_sound') }}
                  </a-checkbox>
                </template>
                <cds-input
                  :show-count="false"
                  v-model:value="formState.soundUrl"
                  :placeholder="t('common.default')"
                  :readonly="true"
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :name="['tenDoiTuong']"
              >
                <template #label>
                  <a-checkbox
                    v-model:checked="formState.warningByAlarmSound"
                    class="text-ems-gray300 font-normal checkbox-custom"
                    :disabled="mode === FORM_MODE.VIEW"
                    @change="onChangeAlarmSound"
                    >{{ t('alert_configuration.alert_by_tone') }}
                  </a-checkbox>
                </template>
                <cds-select
                  :options="ALARM_SOUND"
                  v-model:value="formState.alarmSoundUrl"
                  :maxlength="100"
                  :placeholder="
                    t('alert_configuration.alert_by_tone_placeholder')
                  "
                  :disabled="mode === FORM_MODE.VIEW"
                />
              </a-form-item>
            </div>
            <div>
              <a-form-item
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                :name="['repeatTime']"
              >
                <template #label>
                  <div class="text-ems-gray300 font-normal">
                    {{ t('alert_configuration.alert_duration') }}
                  </div>
                </template>
                <cds-select
                  :options="TIME_WARN"
                  v-model:value="formState.repeatTime"
                  :maxlength="100"
                  :placeholder="
                    t('alert_configuration.alert_duration_placeholder')
                  "
                  :disabled="mode === FORM_MODE.VIEW"
                />
              </a-form-item>
            </div>
          </div>
          <div class="grid grid-cols-4 gap-30px mt-58px">
            <div>
              <a-checkbox
                v-model:checked="formState.popup"
                class="text-ems-gray300 checkbox-custom"
                :disabled="mode === FORM_MODE.VIEW"
                >{{ t('alert_configuration.alert_popup') }}
              </a-checkbox>
            </div>
            <div>
              <a-checkbox
                v-model:checked="formState.liveCamera"
                class="text-ems-gray300 checkbox-custom"
                :disabled="mode === FORM_MODE.VIEW"
                >{{ t('alert_configuration.alert_camera') }}
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="px-16px mt-16px pb-16px">
        <div class="bg-ems-gray800 pt-20px px-20px pb-28px">
          <div
            class="text-ems-gray200 text-16px mb-20px font-semibold uppercase"
          >
            {{ t('alert_configuration.alert_multimedia_config') }}
          </div>
          <div class="h-1px w-full bg-ems-gray700 mt-17px mb-26px"></div>
          <div class="grid grid-cols-4 gap-x-30px gap-y-42px mt-47px">
            <div v-for="item in listOptions" :key="item.value">
              <a-checkbox
                v-model:checked="formState[item.value]"
                class="text-ems-gray300 checkbox-custom"
                :disabled="mode === FORM_MODE.VIEW"
                >{{ item.label }}
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import ClipButton from '@/components/buttons/ClipButton.vue';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { debounce } from 'lodash';
import { useForm } from 'ant-design-vue/es/form';
import { useRoute, useRouter } from 'vue-router';
import { FORM_MODE } from '@/util/common-constant';
import { maxlength, minlength, required } from '@/util/validation';

const { t } = useI18n();
const { dispatch, state } = useStore();
const router = useRouter();
const route = useRoute();

const formRef = ref(null);
const mode = ref(Number(route.query.mode));

const listOptions = [
  { label: t('alert_configuration.alert_VMS'), value: 'sendToVms' },
  { label: t('alert_configuration.alert_email'), value: 'sendToEmail' },
  { label: t('alert_configuration.alert_EMS'), value: 'sendToEms' },
  { label: t('alert_configuration.alert_mobile_app'), value: 'sendToMobile' },
  { label: t('alert_configuration.alert_telegram'), value: 'sendToTele' },
];
const formState = reactive({
  alarmSoundUrl: null,
  id: null,
  ioIds: [
    // ''
  ],
  ios: [
    // {
    //   address: '',
    //   characteristic: '',
    //   country: 0,
    //   createdTime: '',
    //   districtId: 0,
    //   exhibitDetail: '',
    //   fatherExhibit: '',
    //   id: '',
    //   imagesAvatar: '',
    //   ioGroups: [
    //     0
    //   ],
    //   motherExhibit: '',
    //   name: '',
    //   ownerIdCard: '',
    //   provinceId: 0,
    //   status: true,
    //   type: 0,
    //   wardId: 0
    // }
  ],
  liveCamera: false,
  name: '',
  popup: false,
  repeatTime: null,
  sendToEmail: false,
  sendToEms: false,
  sendToMobile: false,
  sendToTele: false,
  sendToVms: false,
  soundUrl: t('common.default'),
  status: true,
  warningByAlarmSound: false,
  warningBySound: false,
  warningConfigObject: [
    // {
    //   configId: 0,
    //   id: 0,
    //   ioId: '',
    //   type: 0,
    // }
  ],
  warningConfigObjectValue: [],
});

const detailAlert = computed(() => state.alertConfiguration.detailAlert);

watch(
  () => detailAlert.value,
  (val) => {
    if (val) {
      formState.alarmSoundUrl = val.alarmSoundUrl;
      formState.id = val.id;
      formState.ios = val.ios;
      formState.liveCamera = val.formState;
      formState.name = val.name;
      formState.popup = val.popup;
      formState.repeatTime = val.repeatTime;
      formState.sendToEmail = val.sendToEmail;
      formState.sendToEms = val.sendToEms;
      formState.sendToMobile = val.sendToMobile;
      formState.sendToTele = val.sendToTele;
      formState.sendToVms = val.sendToVms;
      formState.soundUrl = val.soundUrl;
      formState.status = val.status;
      formState.warningByAlarmSound = val.warningByAlarmSound;
      formState.warningBySound = val.warningBySound;
      formState.warningConfigObject = val.warningConfigObject;
      formState.warningConfigObjectValue = val.warningConfigObject.map(
        (e) => e.ioId
      );
    }
  },
  {
    deep: true,
  }
);

onMounted(async () => {
  if (route.query.id) {
    await dispatch('alertConfiguration/getDetail', route.query.id);
    await dispatch('alertConfiguration/getListProfileByIds', formState.warningConfigObjectValue);
  }
  await dispatch(
    'setTitleHeader',
    t('alert_configuration.alert_configuration')
  );
  await dispatch('alertConfiguration/getListIO', { page: 0, size: 20 });
  if (route.query.listProfile) {
    const response = await dispatch('alertConfiguration/getListProfileByIds', route.query.listProfile);
    response.forEach(item => {
      formState.warningConfigObject.push({
        ioId: item.id,
        type: item.type,
      });
    })
    formState.warningConfigObjectValue = route.query.listProfile
  }
});

const listIO = computed(() => state.alertConfiguration.listIOInGroup);

const onChange = (arr) => {
  if (!arr.length) {
    dispatch('alertConfiguration/getListIO', { page: 0, size: 20 });
  }
};

const onSearch = debounce(function (value) {
  dispatch('alertConfiguration/getListIO', {
    name: value,
    page: 0,
    size: value ? 9999 : 20,
  });
  // listIO.value = [{name: 'Test', id: '1'}]
}, 500);

const TIME_WARN = [
  { label: t('alert_configuration.minute', { minute: 1 }), value: '1' },
  { label: t('alert_configuration.minute', { minute: 2 }), value: '2' },
  { label: t('alert_configuration.minute', { minute: 3 }), value: '3' },
  { label: t('alert_configuration.minute', { minute: 4 }), value: '4' },
  { label: t('alert_configuration.minute', { minute: 5 }), value: '5' },
  { label: t('alert_configuration.minute', { minute: 6 }), value: '6' },
  { label: t('alert_configuration.minute', { minute: 7 }), value: '7' },
  { label: t('alert_configuration.minute', { minute: 8 }), value: '8' },
  { label: t('alert_configuration.minute', { minute: 9 }), value: '9' },
  { label: t('alert_configuration.minute', { minute: 10 }), value: '10' },
];

const ALARM_SOUND = [
  { label: 'asset', value: 'asset' },
  { label: 'apex', value: 'apex' },
  { label: 'radar', value: 'radar' },
  { label: 'classic', value: 'classic' },
];

const STATUS_LIST = [
  { label: t('list_alert.active'), value: true },
  { label: t('list_alert.inactive'), value: false },
];

const rules = {
  warningConfigObjectValue: [required(t('alert_configuration.alert_obj_pick'))],
  repeatTime: [required(t('alert_configuration.alert_duration_placeholder'))],
  name: [
    required(t('alert_configuration.name')),
    minlength(2, t('alert_configuration.name')),
    maxlength(50, t('alert_configuration.name')),
  ],
};

const { validate } = useForm(formState, rules);

const onSave = () => {
  formRef.value.validate();
  validate().then(() => {
    if (mode.value === FORM_MODE.CREATE) {
      dispatch('alertConfiguration/create', formState).then((isSuccess) => {
        if (isSuccess) router.push('/alert-system/list-alert');
      });
    } else {
      dispatch('alertConfiguration/update', formState).then((isSuccess) => {
        if (isSuccess) router.push('/alert-system/list-alert');
      });
    }
  });
};

const onSelect = (id) => {
  const val = listIO.value.find((e) => e.id === id);
  if (val) {
    formState.warningConfigObject.push({
      ioId: id,
      type: val.type,
    });
    const listSelectedId = formState.warningConfigObject.map(item => item.ioId)
    console.log(listSelectedId)
    console.log(listIO.value.filter(item => listSelectedId.includes(item.id)))
    dispatch('alertConfiguration/setSelectedList', listIO.value.filter(item => listSelectedId.includes(item.id)))
  }
};
const onDeSelect = (id) => {
  formState.warningConfigObject = formState.warningConfigObject.filter(
    (e) => e.ioId !== id
  );
  const listSelectedId = formState.warningConfigObject.map(item => item.ioId)
  dispatch('alertConfiguration/setSelectedList', listIO.value.filter(item => listSelectedId.includes(item.id)))
};

const onChangeAlarmSound = (event) => {
  if (!event.target.checked) {
    formState.alarmSoundUrl = null;
  }
  if (event.target.checked && !formState.alarmSoundUrl) {
    formState.alarmSoundUrl = 'asset';
  }
};

onUnmounted(() => {
  dispatch('alertConfiguration/resetData');
});
const windowConst = window;
const onBack = () => {
  if (window.history.state.back) {
    history.back();
  }
};
</script>

<style lang="scss">

.alert-configuration {
  .ant-checkbox-disabled + span {
    color: #ffffff;
    cursor: not-allowed;
  }

  //.ant-checkbox-disabled{
  //  background-color: #5B5B9F;
  //  border-color: #5B5B9F !important;
  //}

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #5B5B9F;
    animation-name: none;
  }
}

</style>
