<template>
  <div class="flex w-full max-h-500px">
    <div class="w-2/4">
      <img
        class="w-full h-full object-cover"
        :src="record.image ? record.image : require('@/static/img/no-img.png')"
        :alt="record.id"
      />
    </div>
    <div class="flex flex-col justify-between gap-10px w-2/4 p-10px">
      <div
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">
          {{
            record.type === OBJECT_TYPE.VEHICLE
              ? t('object_information.license_plate')
              : t('object_information.object_name')
          }}</span
        >
        <span class="w-1/2 text-left">{{
          record.name ? record.name : record.id
        }}</span>
      </div>
      <div
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('identification-event.location') }}</span>
        <span class="w-1/2 text-left">{{ record.location }}</span>
      </div>
      <div
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('identification-event.time-ident') }}</span>
        <span class="w-1/2 text-left">{{
          formatDate(record.createdAt, 'HH:mm:ss DD/MM/YYYY')
        }}</span>
      </div>
      <div
        v-if="record.type === OBJECT_TYPE.VEHICLE"
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('object_tracing.owner') }}</span>
        <span class="w-1/2 text-left">{{
          record.vehicleInfo.vehicleOwner
            ? record.vehicleInfo.vehicleOwner
            : t('object_tracing.unknown')
        }}</span>
      </div>
      <div
        v-if="record.type === OBJECT_TYPE.VEHICLE"
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('object_tracing.classify') }}</span>
        <span class="w-1/2 text-left">{{
          getNameVehicleType(record)
            ? getNameVehicleType(record)
            : t('object_tracing.unknown')
        }}</span>
      </div>
      <div
        v-if="record.type === OBJECT_TYPE.VEHICLE"
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('object_tracing.color') }}</span>
        <span class="w-1/2 text-left">{{
          vehicleColor(record)
            ? vehicleColor(record)
            : t('object_tracing.unknown')
        }}</span>
      </div>
      <div
        v-if="record.type === OBJECT_TYPE.HUMAN"
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('identification-event.age') }}</span>
        <span class="w-1/2 text-left">{{
          record.humanInfo && record.humanInfo.face.age
            ? record.humanInfo.face.age
            : t('object_tracing.unknown')
        }}</span>
      </div>
      <div
        v-if="record.type === OBJECT_TYPE.HUMAN"
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('identification-event.sex') }}</span>
        <span class="w-1/2 text-left">{{
          record.humanInfo && record.humanInfo.face.gender
            ? t(GENDER[record.humanInfo.face.gender])
            : t('object_tracing.unknown')
        }}</span>
      </div>
      <div
        v-if="record.type === OBJECT_TYPE.HUMAN"
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('identification-event.race') }}</span>
        <span class="w-1/2 text-left">{{
          record.humanInfo && record.humanInfo.face.race
            ? t(RACE[record.humanInfo.face.race])
            : t('object_tracing.unknown')
        }}</span>
      </div>
      <div
        class="flex justify-between text-ems-gray300 pb-10px border-b border-b-1 border-b-solid border-b-ems-gray600"
      >
        <span class="w-1/2">{{ t('identification-event.status') }}</span>
        <span
          class="w-1/2 text-left"
          :style="statusColor(record.directionType)"
          >{{
            record.directionType
              ? t(EVENT_STATUS[record.directionType])
              : t('object_tracing.unknown')
          }}</span
        >
      </div>
      <div class="flex justify-between text-ems-gray300 pb-10px">
        <span class="w-1/2">{{ t('object_information.group_belong') }}</span>
        <div
          class="w-1/2"
          :class="record.ioGroup.length === 1 && 'text-left'"
          v-if="record.ioGroup && record.ioGroup.length > 0"
        >
          <span
            class="inline"
            v-for="(item, index) in record.ioGroup"
            :key="index"
          >
            <span
              class="cursor-pointer mr-1"
              :style="getColor(listGroups[item])"
            >
              {{
                listGroupKeyValue[item] && listGroupKeyValue[item].length > 20
                  ? `${listGroupKeyValue[item].substring(0, 20)}...`
                  : listGroupKeyValue[item]
              }}{{ record.ioGroup.length - 1 !== index ? '-' : '' }}
            </span>
          </span>
        </div>
        <div v-else class="w-1/2 text-left">
          {{ t('object_tracing.unknown_group') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatDate } from '@/util/common-utils';
import {
  WARNING_LEVEL,
  GENDER,
  RACE,
  EVENT_STATUS,
  EVENT_STATUS_COLOR,
} from '@/config/Constant.js';
import { OBJECT_TYPE } from '@/util/common-constant.js';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed } from 'vue';

const { t } = useI18n();
const { state } = useStore();
const props = defineProps({
  record: { type: Object, required: true },
});

const getColor = computed(() => (item) => {
  return {
    color:
      item && item.warningLevel
        ? WARNING_LEVEL[item.warningLevel - 1].textColor
        : '#706F80',
  };
});
const listGroupKeyValue = computed(() => state.ioGroup.listGroupKeyValue);
const listGroups = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
const vehicleType = computed(() => state.listSelect.vehicleType);
const getNameVehicleType = (record) => {
  const index = vehicleType.value.findIndex(
    (item) => item.value === record.vehicleInfo.vehicleName
  );
  if (index !== -1) {
    return vehicleType.value[index].name;
  }
  return '';
};

const color = computed(() => state.listSelect.color);
const vehicleColor = (record) => {
  const index = color.value.findIndex(
    (item) => item.value === record.vehicleInfo.vehicleColor
  );
  if (index !== -1) {
    return color.value[index].name;
  }
  return '';
};

const statusColor = (item) => {
  return {
    color: item ? EVENT_STATUS_COLOR[item] : '#F7F0F7',
  };
};
</script>
